<template>
    <v-list density="compact">
        <v-list-subheader>{{ t("module_designs") }}</v-list-subheader>
        <v-list-item prepend-icon="mdi-cube" active-class="accent--text"
            :to="{ name: 'modd_viewer', params: { modd_uuid: dgroup.last_uuid } }" v-for="dgroup in designs_by_name"
            :key="dgroup.name">
            <v-list-item-title v-text="dgroup.name"></v-list-item-title>
            <template v-slot:append>
                <v-btn size="small" variant="text" v-for="design in dgroup.revisions" :key="design.uuid"
                    :to="{ name: 'modd_viewer', params: { modd_uuid: design.uuid } }">
                    <IconRevision :rev="design.revision" />
                </v-btn>
            </template>
        </v-list-item>
    </v-list>
</template>

<script>
import { nameSort, by_revision } from "/src/utils/basics.js"
import IconRevision from "../../renderers/IconRevision.vue"
import { usePitStoreBasics } from "@/stores/app_common";
import { useConstructiblesStore } from "@/stores/constructibles";
export default {
    setup() {
        const store_basics = usePitStoreBasics()
        const constructibles_store = useConstructiblesStore()
        return { ...store_basics, constructibles_store }
    },
    components: { IconRevision },
    computed: {
        modds() { return this.constructibles_store.module_designs },
        designs_by_name() {
            let designs_by_name = []
            this.modds.forEach((modd) => {
                let found_modd_i = designs_by_name
                    .findIndex((found_modd) => found_modd.name == modd.name)
                if (found_modd_i == -1) {
                    designs_by_name.push({
                        name: modd.name,
                        revisions: [modd],
                        last_uuid: modd.uuid,
                    })
                } else {
                    designs_by_name[found_modd_i].revisions.push(modd)
                    designs_by_name[found_modd_i].last_uuid = modd.uuid
                }
            })
            designs_by_name.forEach((found_modd) => {
                found_modd.revisions.sort(by_revision)
            })
            designs_by_name.sort((a, b) => nameSort(a.name, b.name))
            return designs_by_name
        }
    }
}
</script>